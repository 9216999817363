const StepProgress = ({ step, historic, showPopUpStatus, setShowStatus }) => {
  return (
    <div className="manager-request-table-result__arrows">
      <div
        className={`manager-request-table-result__arrow manager-request-table-result__arrow--first
              } ${
                step >= 1 ? 'manager-request-table-result__arrow--active' : ''
              }`}
        onMouseEnter={() => {
          showPopUpStatus('Formulário Enviado');
        }}
        onMouseLeave={() => setShowStatus('')}
      />
      <div
        className={`manager-request-table-result__arrow
              } ${
                step >= 2 ? 'manager-request-table-result__arrow--active' : ''
              }`}
        onMouseEnter={() => {
          showPopUpStatus('Aguardando Revisão do RH');
        }}
        onMouseLeave={() => setShowStatus('')}
      />
      <div
        className={`manager-request-table-result__arrow
              } ${
                step >= 3 || historic === 'Movimentação de Inclusão Enviada'
                  ? 'manager-request-table-result__arrow--active'
                  : ''
              }`}
        onMouseEnter={() => {
          showPopUpStatus('Aguardando correção do beneficiário');
        }}
        onMouseLeave={() => setShowStatus('')}
      />
      <div
        className={`manager-request-table-result__arrow
              } ${
                historic === 'Movimentação de Inclusão Enviada'
                  ? 'manager-request-table-result__arrow--active'
                  : ''
              }`}
        onMouseEnter={() => {
          showPopUpStatus('Movimentação de Inclusão Enviada');
        }}
        onMouseLeave={() => setShowStatus('')}
      />
    </div>
  );
};

export default StepProgress;
