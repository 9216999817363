import { useState } from 'react';
import './style.scss';
import moment from 'moment';

const Top10PoliciesOwnersLifetime = ({
  lifetimePolicyOwners,
  graphicDataInicial,
  graphicDataFinal
}) => {
  const [showTitle, setShowTitle] = useState(null);

  return (
    <div className="top-10-lifetime-table">
      <div className="top-10-lifetime-table__header">
        <span className="top-10-lifetime-table__subtitle">
          <strong className="top-10-lifetime-table__title">
            10 maiores vitalícios
          </strong>{' '}
          (
          {graphicDataInicial
            ? moment(graphicDataInicial).format('DD/MM/YY')
            : moment().startOf('year').format('DD/MM/YY')}{' '}
          a{' '}
          {graphicDataFinal
            ? moment(graphicDataFinal).format('DD/MM/YY')
            : moment().format('DD/MM/YY')}
          )
        </span>
      </div>
      <div className="top-10-lifetime-table__content">
        {lifetimePolicyOwners?.map((item) => (
          <div
            className="top-10-lifetime-table__content-item"
            key={item.razao_social}
          >
            <span
              className="top-10-lifetime-table__label label-truncate"
              onMouseEnter={() => {
                setShowTitle(item.razao_social);
              }}
              onMouseLeave={() => setShowTitle(null)}
            >
              {item.razao_social}
              {showTitle === item.razao_social && (
                <div className="top-10-lifetime-table__title-pop-up">
                  {item.razao_social}
                </div>
              )}
            </span>
            <span className="top-10-lifetime-table__value">
              {item.vitalicioFormatado}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Top10PoliciesOwnersLifetime;
