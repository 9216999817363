import { useEffect, useState } from 'react';
import Sidebar from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import Main from 'apps/broker/components/MainContent';
import FinancialGeneralInformation from './components/FinancialGeneralInfo';
import IncomingInformation from './components/IncomingTable';
import services from 'apps/broker/services';
import './style.scss';
import moment from 'moment';

const BrokerFinancial = () => {
  const [policyOwnerList, setPolicyOwnerList] = useState([]);
  const [policyOwnerId, setPolicyOwnerId] = useState(null);
  const [incomingInitialDate, setIncomingInitialDate] = useState('');
  const [incomingFinalDate, setIncomingFinalDate] = useState('');
  const [incoming, setIncoming] = useState([]);
  const [commissionMonth, setCommissionMonth] = useState({});
  const [commissionYear, setCommissionYear] = useState({});
  const [lifetimePolicyOwners, setLifetimePolicyOwners] = useState([]);
  const [incomingMessage, setIncomingMessage] = useState('');
  const [graphicDataInicial, setGraphicDataInicial] = useState('');
  const [graphicDataFinal, setGraphicDataFinal] = useState('');
  const [graphicData, setGraphicData] = useState([]);

  const getPolicyOwners = async () => {
    const data = await services.financial.getAllPolicyOwners();
    if (data) {
      const options = data?.map((item) => {
        return { value: item.id, option: item.razao_social };
      });
      setPolicyOwnerList(options);
      setPolicyOwnerId(options[0].value);
    }
  };

  const getIncoming = async () => {
    if (policyOwnerId) {
      setIncoming([]);
      const estipulante_id = policyOwnerId;
      const data_inicial = incomingInitialDate ? incomingInitialDate : '';
      const data_final = incomingFinalDate ? incomingFinalDate : '';
      const data = await services.financial.getIncomingByPolicyOwnerId(
        estipulante_id,
        data_inicial,
        data_final
      );

      if (!data || data.contratos?.length === 0) {
        !data_inicial
          ? setIncomingMessage(`Não há contratos ativos no período padrão entre 01/01/2023 
            e ${moment().format('DD/MM/YYYY')}`)
          : setIncomingMessage(`Não há contratos ativos entre ${moment(data_inicial).format('DD/MM/YYYY')} 
          e ${moment(data_final).format('DD/MM/YYYY')}`);
      } else if (data) {
        setIncomingMessage('');
        setIncoming(formatCompaniesName(data));
      }
    }
  };

  const getCommissionByActualMonth = async () => {
    const response = await services.financial.getFinancialByActualMonth();
    if (response.status === 200) {
      response.data.totalComissionamento?.forEach((item) => {
        setCommissionMonth({
          agency: item.valorTotalAgenciamento,
          billing: item.valorTotalFatura,
          lifetime: item.valorTotalVitalicio,
          lifetimePerBeneficiary: item.valorVitalicioPorBeneficiario
        });
      });
    } else if (response.status === 500) {
      setCommissionMonth({
        agency: 'R$ 0,00',
        billing: 'R$ 0,00',
        lifetime: 'R$ 0,00',
        lifetimePerBeneficiary: 'R$ 0,00'
      });
    }
  };

  const getCommissionByActualYear = async () => {
    const response = await services.financial.getFinancialByActualYear();
    if (response.status === 200) {
      response.data?.forEach((item) => {
        setCommissionYear({
          agency: item.valorTotalAgenciamento,
          billing: item.valorTotalFatura,
          lifetime: item.valorTotalVitalicio
        });
      });
    } else if (response.status === 500) {
      setCommissionYear({
        agency: 'R$ 0,00',
        billing: 'R$ 0,00',
        lifetime: 'R$ 0,00'
      });
    }
  };

  const formatCompaniesName = (data) => {
    return data.map((item) => ({
      ...item,
      razao_social: item.razao_social
        .toLowerCase()
        .split(' ')
        .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1))
        .join(' ')
    }));
  };

  const getFinancialGraphicData = async () => {
    const data_inicial = graphicDataInicial ? graphicDataInicial : '';
    const data_final = graphicDataFinal ? graphicDataFinal : '';
    const response = await services.financial.getGraphicData(
      data_inicial,
      data_final
    );
    if (response.status === 200) {
      setGraphicData(response.data.graphic);
      setLifetimePolicyOwners(formatCompaniesName(response.data.lifetime));
    }

    if (response.data.lifetime?.length === 0) {
      setIncomingMessage('Dados não encontrados.');
    }
  };

  useEffect(() => {
    getPolicyOwners();
    getCommissionByActualMonth();
    getCommissionByActualYear();
  }, []);

  useEffect(() => {
    getIncoming();
  }, [policyOwnerId, incomingFinalDate]);

  useEffect(() => {
    getFinancialGraphicData();
  }, [graphicDataFinal]);

  return (
    <div className="broker-financial">
      <Sidebar />
      <div className="broker-financial-container">
        <Header title="Financeiro" />
        <Main>
          <div className="broker-financial-content">
            <FinancialGeneralInformation
              commissionMonth={commissionMonth}
              commissionYear={commissionYear}
              lifetimePolicyOwners={lifetimePolicyOwners}
              graphicDataInicial={graphicDataInicial}
              setGraphicDataInicial={setGraphicDataInicial}
              graphicDataFinal={graphicDataFinal}
              setGraphicDataFinal={setGraphicDataFinal}
              graphicData={graphicData}
              getFinancialGraphicData={getFinancialGraphicData}
            />
            <div className="broker-financial-horizontal-bar"></div>
            <div>
              <IncomingInformation
                policyOwnerList={policyOwnerList}
                policyOwnerId={policyOwnerId}
                setPolicyOwnerId={setPolicyOwnerId}
                incomingInitialDate={incomingInitialDate}
                setIncomingInitialDate={setIncomingInitialDate}
                incomingFinalDate={incomingFinalDate}
                setIncomingFinalDate={setIncomingFinalDate}
                incomingMessage={incomingMessage}
                incoming={incoming}
              />
            </div>
          </div>
        </Main>
      </div>
    </div>
  );
};

export default BrokerFinancial;
