import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2.4375rem 3.6875rem 0 2.6875rem;

  background: #fff;

  nav {
    a {
      font-size: 1.125rem;
      font-family: "Avenir Next GEO W05 Demi";
      color: #3B3838;
      line-height: 1.5rem;
    }
  }

  .toggle-menu {
    display: none;
  }

  @media(max-width: 732px) {
    padding-right: 0;

    .toggle-menu {
      display: block;
    }

    nav {
      display: none;
    }
  }
`;