import React, { useState } from 'react';
// import { useHistory} from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';

import { FiLogIn } from 'react-icons/fi';
import { FiCheckCircle } from 'react-icons/fi';
import { Wraper } from './styles';
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import api from 'services/api.js';

import { makeStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));

export default function Signup(props) {
  const classes = useStyles();
  const { control, handleSubmit } = useForm();

  // const history = useHistory()

  const [stateSucess, setStateSucess] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const handleCnpjChange = (field, target) => {
    field.onChange(target.value);
  };

  const onSubmit = (data) => {
    api
      .post('/cadastro-corretor', data)
      .then((resp) => {
        setStateSucess(true);
      })
      .catch((err) => {
        setErrorApi(err.response.data);
      });
  };

  return (
    <Wraper>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img
                src={LogoInnoa}
                width="150"
                alt="logo Innoa"
                loading="lazy"
              />
            </a>
          </div>
        </Container>
      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  {stateSucess ? (
                    <CardContent>
                      <div className="largeIcon">
                        <FiCheckCircle />
                      </div>
                      <div className="title">
                        <h1>Cadastro realizado</h1>
                      </div>
                      <div className="msg-success">
                        <span>
                          Você receberá um email para confirmação da conta.
                        </span>
                      </div>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <div className="title">
                        <h1>Cadastro corretora</h1>
                      </div>
                      <div className="form">
                        <form
                          validate="true"
                          autoComplete="off"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="flex-center"></div>
                          <FormControl fullWidth className="form-controll">
                            <label className="default">CNPJ</label>
                            <Controller
                              name="cnpj"
                              control={control}
                              rules={{ required: false }}
                              render={({ field }) => (
                                <InputMask
                                  value={field.value}
                                  maskChar={null}
                                  mask="99.999.999/9999-99"
                                  type="text"
                                  onChange={({ target }) =>
                                    handleCnpjChange(field, target)
                                  }
                                />
                              )}
                            />
                          </FormControl>

                          <label className="default">Razão Social</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="razaoSocial"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  label="razaoSocial"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <label className="default">Nome Fantasia</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="nomeFantasia"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  label="nomeFantasia"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <label className="default">Email</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="email"
                                  label="E-mail"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <label className="default">Senha</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="password"
                                  label="Senha"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <label className="default">Susep</label>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="susep"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  label="susep"
                                  required
                                />
                              )}
                            />
                          </FormControl>
                          {errorApi && (
                            <span className="error-msg">{errorApi}</span>
                          )}
                          <div className="flex-center mb-1">
                            <button type="submit">
                              Entrar{' '}
                              <span className="icon">
                                <FiLogIn />
                              </span>{' '}
                            </button>
                          </div>
                        </form>
                      </div>

                      {/* <div className="form-link">
                      <div>
                        <Link to="/forgot"> Esqueci a minha senha</Link>
                      </div>
                      <div>
                        <a href="/">Ainda não sou cliente</a>
                      </div>
                    </div> */}
                    </CardContent>
                  )}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Wraper>
  );
}
