import React from 'react';
import {
  PaginationContainer,
  PaginationButton
} from './styles';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  siblingCount = 1
}) {
  // Função para gerar o array de páginas
  const generatePageNumbers = () => {
    const pages = [];

    // Primeira página sempre visível
    pages.push(1);

    // Páginas do meio
    const startPage = Math.max(2, Math.min(
      currentPage - siblingCount,
      totalPages - (2 + siblingCount * 2)
    ));

    const endPage = Math.min(
      totalPages - 1,
      Math.max(
        currentPage + siblingCount,
        1 + (2 + siblingCount * 2)
      )
    );

    // Adicionar reticências se necessário
    const showStartEllipsis = startPage > 2;
    const showEndEllipsis = endPage < totalPages - 1;

    if (showStartEllipsis) {
      pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (showEndEllipsis) {
      pages.push('...');
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <PaginationContainer>
      <PaginationButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft />
      </PaginationButton>

      {pageNumbers.map((page, index) => (
        <PaginationButton
          key={index}
          onClick={() => typeof page === 'number' && onPageChange(page)}
          current={page === currentPage}
          disabled={page === '...'}
        >
          {page}
        </PaginationButton>
      ))}

      <PaginationButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        <ChevronRight />
      </PaginationButton>
    </PaginationContainer>
  );
};
