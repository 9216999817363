import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Wraper } from './styles';
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import { makeStyles } from '@material-ui/core/styles';
import services from 'apps/lp/services';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
}));

const ConfirmationAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, token } = useParams();

  const [messageResp, setMessageResp] = useState();

  const postConfirm = async () => {
    const response = await services.user.createConfirmation(id, token);

    if (response.status === 200) {
      setMessageResp(response.data);
      history.push('/login');
    } else {
      setMessageResp(response.data);
    }
  };

  useEffect(() => {
    postConfirm();
  }, []);

  return (
    <Wraper>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoInnoa} width="150" alt="logo Innoa" loading="lazy" />
            </a>
          </div>
        </Container>
      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  <CardContent>
                    <div className="title">
                      <h1>{messageResp}</h1>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Wraper>
  );
};

export default ConfirmationAccount;
