import { Link, Button, makeStyles } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useState } from "react";
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import Drawer from "apps/lp/components/Drawer";
import { Container } from "./styles";

const useStyles = makeStyles({
  content: {
    listStyle: "none",
    marginTop: 24,
  },
  link: {
    display: "block",
    color: "#3B3838",
    fontFamily: "Avenir Next GEO W05 Demi",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    paddingLeft: "2rem",
  },
});

export default function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();

  function handleCloseDrawer() {
    setIsDrawerOpen(false);
  }

  function handleOpenDrawer() {
    setIsDrawerOpen(true);
  }

  return (
    <Container>
      <Link href="https://innoaseguros.com.br/">
        <img src={LogoInnoa} alt="logo Innoa" width="150px" />
      </Link>
      <nav>
        <Link href="login">Login</Link>
      </nav>

      <Button className="toggle-menu" onClick={handleOpenDrawer}>
        <Menu fontSize="large" />
      </Button>

      <Drawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        onOpen={handleOpenDrawer}
      >
        <ul className={classes.content}>
          <li style={{ marginTop: 24 }}>
            <Link href="login" className={classes.link}>
              Login
            </Link>
          </li>
        </ul>
      </Drawer>
    </Container>
  );
}
