import React from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

const EditEstipulanteForm = ({
  details,
  emailsEmpresa,
  onSubmit,
  onCancel,
  successEst,
  removeEmail,
  setDelEmail,
  delEmail,
  setSuccessEst,
  setEditEstipulante
}) => {
  console.log('details', details)
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      cnpj: details.cnpj,
      razao_social: details.razao_social,
      cep: details.cep,
      logradouro: details.logradouro,
      numero: details.numero,
      complemento: details.complemento || '',
      localidade: details.localidade,
      dataEstipulante: details.clienteData.dataVinculo
        ? new Date(details.clienteData.dataVinculo)
        : null,
      id_estipulante: details.id_estipulante,
      emails: []
    }
  });

  const {
    fields: emailsField,
    append: emailsAppend,
    remove: emailsRemove
  } = useFieldArray({ control, name: 'emails' });

  return (
    <div className="card">
      <br />
      <h3>Editar informações do Estipulante</h3>
      <div className="addGestor">
        <form onSubmit={handleSubmit(onSubmit)}>
          {successEst && (
            <span className="message animeLeft">
              Informações salvas.
            </span>
          )}
          <div className="input-group">
            <label>CNPJ</label>
            <input
              maxLength={50}
              {...register('cnpj', { required: true })}
            ></input>
            {errors.cnpj && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <label>Razão Social</label>
            <input
              maxLength={50}
              {...register('razao_social', { required: true })}
            ></input>
            {errors.razao_social && <span className="error-msg">Campo obrigatório</span>}
          </div>

          <div className="input-group">
            <label>Data de inicio de vinculo com o Estipulante</label>
            <Controller
              name="dataEstipulante"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  className="input-data"
                  selected={value}
                  locale="ptBR"
                  onChange={onChange}
                  dateFormat="dd/MM/yyyy"
                />
              )}
            />
            {errors.dataEstipulante && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <label>CEP</label>
            <input
              maxLength={50}
              {...register('cep', { required: true })}
            ></input>
            {errors.cep && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <label>Endereço</label>
            <input
              maxLength={50}
              {...register('logradouro', { required: true })}
            ></input>
            {errors.logradouro && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <label>Número</label>
            <input
              type="text"
              {...register('numero', { required: true })}
            ></input>
            {errors.numero && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <label>Complemento</label>
            <input
              {...register('complemento')}
            ></input>
          </div>
          <div className="input-group">
            <label>Localidade</label>
            <input
              {...register('localidade', { required: true })}
            ></input>
            {errors.localidade && <span className="error-msg">Campo obrigatório</span>}
          </div>
          <div className="input-group">
            <br />
            <label>E-mails da Empresa</label>
            {emailsEmpresa?.map((item, index) => {
              return (
                <div className="input-group" key={index}>
                  <div className="remove-btn-area">
                    {' '}
                    {emailsEmpresa.length > 0 && (
                      <button
                        type="button"
                        className="remove-field"
                        onClick={() => setDelEmail(index)}
                      >
                        Excluir
                      </button>
                    )}
                  </div>
                  <input
                    readOnly
                    defaultValue={emailsEmpresa[index].email_empresa}
                    type="text"
                  />
                  {delEmail === index && (
                    <div>
                      <p>Você tem certeza que deseja excluir esse e-mail?</p>
                      <div className="btn-area">
                        <button
                          className="send-btn"
                          type="button"
                          onClick={(e) =>
                            removeEmail(
                              e,
                              emailsEmpresa[index].email_empresa,
                              details.id_estipulante
                            )
                          }
                        >
                          Sim
                        </button>
                        <button
                          className="send-btn"
                          type="button"
                          onClick={(e) => setDelEmail(false)}
                        >
                          Não
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {emailsField?.map((item, index) => {
              return (
                <div className="input-group" key={item.id}>
                  <div className="remove-btn-area">
                    {' '}
                    {emailsField.length > 0 && (
                      <button
                        type="button"
                        className="remove-field"
                        onClick={() => emailsRemove(index)}
                      >
                        remover
                      </button>
                    )}
                  </div>
                  <input
                    type="text"
                    {...register(`emails.${index}.nome`, {
                      required: false
                    })}
                  />
                </div>
              );
            })}
            <button
              type="button"
              className="add"
              onClick={() => emailsAppend({ nome: '' })}
            >
              + Adicionar mais um e-mail.
            </button>
          </div>

          {/* Campo hidden com o id_estipulante */}
          <input
            type="hidden"
            {...register('id_estipulante')}
          />

          <div className="btn-area">
            <button className="send-btn" type="submit">
              Atualizar
            </button>
            <button
              className="send-btn"
              type="button"
              onClick={(e) => {
                setSuccessEst(false);
                setEditEstipulante(false);
                onCancel();
              }}
            >
              {successEst ? 'Fechar' : 'Cancelar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEstipulanteForm;
