import React from 'react'
import logoAWS from 'assets/imgs/aws.png'
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Wraper, GridFooter } from './styles'

const Footer = () => {
  return (
    <Wraper>
      <footer>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <img src={LogoInnoa} width="120px" alt="logo" />
            </Grid>
            <Grid item xs={6}>
              <div className="flex-content-end"><img className="logo-aws" src={logoAWS} alt="aws" /></div>
            </Grid>
          </Grid>
          
          <GridFooter>
            <div className="list-contact contato">
              <div className="contact-info">
                  INNOA CORRETORA DE SEGUROS LTDA
                  <br /> 11.410.952/0001-08
                  <br />Registrada na SUSEP sob o nº 65595J
                </div>
              <div className="contact-info">
                Rua Álvaro Rodrigues 182 conj. 91
                  <br /> Brooklin Paulista - 04582-000
                  <br /> São Paulo - SP Brasil
                </div>
              <div className="contact-info">
                  rodrigo.pedroni@innoaseguros.com.br
                  <br /> Whatsapp: (11) 2308-9672
                </div>
            </div>



            <div className="seguros">
              <h6>Seguros financeiros</h6>
              <ul className="list-footer">
                <li>D&O - RC Administradores</li>
                <li>E&O - RC Profissional</li>
                <li>Seguro Garantia</li>
                <li>Seguro Cyber</li>
                <li>Seguro de Crédito</li>
              </ul>
            </div>


            <div className="beneficios">
              <h6>Benefícios</h6>
              <ul className="list-footer">
                <li>Seguro Saúde</li>
                <li>Seguro de Vida</li>
                <li>Seguro Odontológico</li>
                <li>Previdência Capitalização</li>
              </ul>
            </div>

            <div className="patrimoniais">
              <h6>Patrimoniais</h6>
              <ul className="list-footer">
                <li>Seguro para frota</li>
                <li>Seguro de transportes</li>
                <li>Seguro para equipamentos</li>
                <li>Seguro de Incêndio e Lucros Cessantes</li>
              </ul>
            </div>

          </GridFooter>

        </Container>
      </footer>
    </Wraper>
  )
}

export default Footer;