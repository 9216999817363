import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiLogIn } from 'react-icons/fi';
import { Wraper } from './styles';
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import api from 'services/api.js';

import { makeStyles } from '@material-ui/core/styles';

import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

export default function AlterarSenha(props) {
  const userKey = 'jwt_token_user';

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const [errors, setErrors] = useState();
  const [resp, setResp] = useState('');

  const { control, handleSubmit } = useForm();

  const history = useHistory();

  const { tipoUsuario, id, token } = useParams();

  const RHonSubmit = (data) => {
    if (data.password !== data.confirmation_password) {
      setErrors('Senhas diferentes, preencha os campos corretamente.');
      return;
    }
    setLoading(true);
    const body = {
      password: data.password
    };
    api
      .post(`/alterar-senha/rh/${id}/${token}`, body)
      .then((resp) => {
        setErrors();
        setResp(
          'Senha alterada com sucesso, você será redirecionado para o login'
        );
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        setErrors(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CRonSubmit = (data) => {
    if (data.password !== data.confirmation_password) {
      setErrors('Senhas diferentes, preencha os campos corretamente.');
      return;
    }
    setLoading(true);
    const body = {
      password: data.password
    };
    api
      .post(`/alterar-senha/corretora/${id}/${token}`, body)
      .then((resp) => {
        setErrors();
        setResp(
          'Senha alterada com sucesso, você será redirecionado para o login'
        );
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        setErrors(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    setLoading(false);
  };

  useEffect(() => {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);
    if (userData?.corretora) {
      history.push('/corretor/home');
    }
    if (userData?.estipulante) {
      history.push('/rh/home');
    }
  });

  return (
    <Wraper>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img
                src={LogoInnoa}
                width="150px"
                alt="logo Innoa"
                loading="lazy"
              />
            </a>
          </div>
        </Container>
      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  <CardContent>
                    <div className="title">Troca de senha</div>
                    <div className="form">
                      <form
                        validate="true"
                        autoComplete="off"
                        onSubmit={handleSubmit(
                          Number(tipoUsuario) === 1 ? RHonSubmit : CRonSubmit
                        )}
                      >
                        <div className="flex-center">
                          {errors && <div className="erro-msg"> {errors}</div>}
                        </div>
                        <FormControl fullWidth className="form-controll">
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="password"
                                label="Nova senha"
                                required
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl fullWidth className="form-controll">
                          <Controller
                            name="confirmation_password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="password"
                                label="Insira novamente a nova senha"
                                required
                              />
                            )}
                          />
                        </FormControl>

                        <div className="flex-center mb-1">
                          <button type="submit">
                            Enviar{' '}
                            <span className="icon">
                              <FiLogIn />
                            </span>{' '}
                          </button>
                        </div>
                        {resp && <div className="resp-success">{resp}</div>}
                      </form>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Wraper>
  );
}
