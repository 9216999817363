import { useState } from 'react';
import { Checkbox } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import OrderRequestDate from '../OrderRequestDate';
import DeleteMoviments from '../DeleteMoviments';
import './style.scss';

const OpenEditTableHeader = ({
  editMode,
  beneficiariesFilter,
  setSelectedCheckboxes,
  setEditMode,
  setOrderSelected,
  orderSelected,
  anchorEl,
  setAnchorEl,
  selectedCheckboxes,
  popupPositiveClickHandler
}) => {
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);

  const clickMasterCheckboxHandler = ({ target }) => {
    const checked = target.checked;

    if (checked) {
      const allIdFromBeneficiarios = beneficiariesFilter.map(
        (beneficiario) => beneficiario.movimentacao_id
      );
      setSelectedCheckboxes(allIdFromBeneficiarios);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }

    if (!checked) {
      setSelectedCheckboxes([]);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }
  };

  const editModeClickHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  return (
    <div className="edit-table-header">
      <div className="edit-table-header__master-checkbox">
        {editMode === false ? (
          <BiEdit
            className="edit-table-header__button-edit"
            onClick={editModeClickHandler}
          />
        ) : (
          <Checkbox
            className="edit-table-header__checkbox"
            onChange={clickMasterCheckboxHandler}
            checked={masterCheckboxChecked}
            sx={{
              color: '#1E2552',
              '&.Mui-checked': {
                color: '#1E2552'
              }
            }}
          />
        )}
      </div>
      <p className="edit-table-header__label">Tipo</p>
      <p className="edit-table-header__label">Beneficiário</p>
      <p>
        <span className="edit-table-header__label">Data do Pedido </span>
        <OrderRequestDate
          setOrderSelected={setOrderSelected}
          orderSelected={orderSelected}
          styleClassIcons="edit-table-header__icon-arrow"
        />
      </p>
      <p className="edit-table-header__label">Titularidade</p>
      <p className="edit-table-header__label">Estipulante ou Sub Estipulante</p>
      <p className="edit-table-header__label">Produto</p>
      <p className="edit-table-header__label">Status</p>
      {editMode === true && (
        <DeleteMoviments
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          selectedCheckboxes={selectedCheckboxes}
          editModeClickHandler={editModeClickHandler}
          popupPositiveClickHandler={popupPositiveClickHandler}
        />
      )}
    </div>
  );
};

export default OpenEditTableHeader;
