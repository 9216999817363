import http from 'services/http';

const getAllPolicyOwners = async () => {
  const res = await http.get('/incoming/policy-owners/');
  return res.data;
};

const getIncomingByPolicyOwnerId = async (
  estipulante_id,
  data_inicial,
  data_final
) => {
  const res = await http.get(
    `/incoming/?estipulante_id=${estipulante_id}&data_inicial=${data_inicial}&data_final=${data_final}`
  );
  return res.data;
};

const getFinancialByActualMonth = async () => {
  const res = await http.get('/financial/actual-month/');
  return res;
};

const getFinancialByActualYear = async () => {
  const res = await http.get('/financial/actual-year/');
  return res;
};

const getGraphicData = async (data_inicial, data_final) => {
  const res = await http.get(`/financial-graphic/?data_inicial=${data_inicial}&data_final=${data_final}`);
  return res;
};

const modules = {
  getAllPolicyOwners,
  getIncomingByPolicyOwnerId,
  getFinancialByActualMonth,
  getFinancialByActualYear,
  getGraphicData,
};

export default modules;
