import { useEffect, useState } from 'react';
import {
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  CustomizedAxisTick,
  CustomizedShape,
  CustomizedTooltip
} from './CustomizedAxisTick';
import monthsObject from 'utils/objects/optionsMonth';
import './style.scss';

const FinancialGraphic = ({
  graphicData,
  getFinancialGraphicData,
  graphicDataFinal
}) => {
  const [agencyDataGraph, setAgencyDataGraph] = useState(null);
  const [lifetimeDataGraph, setLifetimeDataGraph] = useState(null);
  const [draggMonth, setDraggMonth] = useState(0);
  const [storedDropPoint, setStoredDropPoint] = useState(0);
  const [draggable, setDraggable] = useState(false);
  const [positionStart, setPositionStart] = useState(undefined);

  const graphsDomainY = [0, 350000];
  const lifetime = '#456aed';
  const agency = '#1e2552';

  const getGraphicData = () => {
    const agency = graphicData?.map((item) => {
      const { faturamento, vitalicio, ...rest } = item;
      return { ...rest, y: rest.agenciamento };
    });

    const lifetime = graphicData?.map((item) => {
      const { faturamento, agenciamento, ...rest } = item;
      return { ...rest, y: rest.vitalicio };
    });

    setAgencyDataGraph(agency);
    setLifetimeDataGraph(lifetime);
  };

  const formatterDataToDomainX = (array) => {
    if (!array) return [];
    
    const orderByDate = array?.sort((a, b) => {
      const { date: date_a } = a;
      const { date: date_b } = b;

      const monthA = Number(date_a.slice(5));
      const monthB = Number(date_b.slice(5));
      
      const yearA = Number(date_a.slice(0, 4));
      const yearB = Number(date_b.slice(0, 4));

      if (yearA < yearB) {
        return -1;
      }
      if (yearA > yearB) {
        return 1;
      }
      if (monthA < monthB) {
        return -1;
      }
      return 1;
    });

    const formatedData = orderByDate?.map((item, index) => {
      const { date } = item;
      const monthTypeNumber = date?.slice(0, 2);
      item.mes = monthsObject[monthTypeNumber];
      item.index = index;
      return item;
    });

    return formatedData;
  };

  const draggableScatterHandler = (e) => {
    const ticks = document.querySelectorAll('.recharts-cartesian-axis-tick');

    const firstTick = ticks[0]?.getAttribute('x1');
    const secondTick = ticks[1]?.getAttribute('x1');
    const tickScale = Math.round(secondTick - firstTick);

    if (draggable) {
      if (!e || !e?.xValue) {
        setStoredDropPoint((prevState) => prevState + draggMonth);
        setDraggMonth(0);
        setPositionStart(undefined);
        return setDraggable(false);
      }
      if (e && e?.xValue) {
        const xValue = window.event.x - positionStart;

        const xValueYear = xValue / tickScale;

        setDraggMonth(xValueYear);
      }
    }
  };

  const renderCommissioningMonthDomain = () => {
    const formatedData = formatterDataToDomainX(graphicData);
    const oldestAdjust = formatedData[0]?.index;
    const latestAdjust = formatedData[formatedData.length - 1]?.index;
    let oldestDomain;
    let latestDomain;

    const interval = latestAdjust - oldestAdjust;

    if (interval > 5) {
      if (latestAdjust - draggMonth - storedDropPoint > interval) {
        setDraggMonth(0);
        setStoredDropPoint(0);

        return [latestAdjust - 5 - 0.25, latestAdjust + 0.25];
      } else if (interval - 5 - draggMonth - storedDropPoint < 0) {
        setDraggMonth(0);
        setStoredDropPoint(interval - 5);

        return [oldestAdjust - 0.25, oldestAdjust + 5 + 0.25];
      }

      oldestDomain = latestAdjust - 5 - 0.25 - draggMonth - storedDropPoint;
      latestDomain = latestAdjust + 0.25 - draggMonth - storedDropPoint;
      return [oldestDomain, latestDomain];
    }

    return [oldestAdjust - 0.25, latestAdjust + 0.25];
  };

  const renderXDomain = () => {
    const xDomain = renderCommissioningMonthDomain();
    const oldestDomain = xDomain[0];
    const latestDomain = xDomain[1];
    const yearInterval = latestDomain - oldestDomain;

    const ticks = [];

    for (let index = 0; index <= yearInterval; index += 1) {
      const indexDomain = Number(oldestDomain.toFixed(0)) + index;
      ticks.push(indexDomain);
    }
    return ticks;
  };

  const formatCurrency = (value) => `R$ ${value.toLocaleString('pt-BR')}`;

  useEffect(() => {
    getFinancialGraphicData();
    getGraphicData();
  }, [graphicDataFinal]);

  useEffect(() => {
    getGraphicData();
  }, [graphicData]);

  return (
    <div className="financial-graphic">
      <span className="financial-graphic__title">Comissionamento x mês</span>
      <ResponsiveContainer>
        <ScatterChart
          height={450}
          width={350}
          margin={{
            top: 10,
            right: 20,
            bottom: 20,
            left: 0
          }}
          onMouseMove={draggableScatterHandler}
          onMouseDown={() => {
            setDraggable(true);
            setPositionStart(window.event.x);
          }}
          onMouseUp={() => {
            setStoredDropPoint((prevState) => prevState + draggMonth);
            setDraggMonth(0);
            setPositionStart(undefined);
            setDraggable(false);
          }}
          onMouseLeave={() => {
            setStoredDropPoint((prevState) => prevState + draggMonth);
            setDraggMonth(0);
            setPositionStart(undefined);
            setDraggable(false);
          }}
        >
          <XAxis
            type="number"
            interval={0}
            tick={<CustomizedAxisTick data={graphicData} />}
            dataKey="index"
            name="Ano"
            tickSize={2}
            ticks={renderXDomain()}
            scale="linear"
            domain={renderCommissioningMonthDomain()}
            allowDataOverflow={true}
          />
          <YAxis
            type="number"
            tick={{ fontSize: 11 }}
            width={100}
            dataKey="y"
            tickFormatter={formatCurrency}
            interval={0}
            tickMargin={5}
            tickSize={4}
            scale="linear"
            domain={graphsDomainY}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomizedTooltip />}
            wrapperStyle={{ outline: 'none' }}
            contentStyle={{ color: 'red' }}
          />
          <Scatter
            data={lifetimeDataGraph}
            dataKey="vitalicio"
            name="Vitalício"
            lineJointType="linear"
            fill={lifetime}
            line={{ strokeWidth: 0.7, strokeDasharray: 'false' }}
            shape={<CustomizedShape color={lifetime} />}
          />
          <Scatter
            data={agencyDataGraph}
            dataKey="agenciamento"
            name="Agenciamento"
            lineJointType="linear"
            fill={agency}
            line={{ strokeWidth: 0.7, strokeDasharray: 'false' }}
            shape={<CustomizedShape color={agency} />}
          />
          <Legend
            verticalAlign="top"
            align="center"
            iconSize={8}
            wrapperStyle={{ paddingTop: '5px', paddingBottom: '5px' }}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FinancialGraphic;
