import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiLogIn } from 'react-icons/fi';
import { Wraper } from './styles';
import LogoInnoa from 'assets/imgs/logo-innoa.png';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import { useUser } from 'contexts/user';
import { useForm, Controller } from 'react-hook-form';
import { usePostHog } from 'posthog-js/react';
import services from 'apps/broker/services';
import servicesRh from 'apps/hr/services';

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#f0e7ff'
  },
  tabs: {
    fontFamily: 'Avenir Next GEO W05 Demi'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const Login = (props) => {
  const posthog = usePostHog();
  const userKey = 'jwt_token_user';
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setUser } = useUser();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorRH, setErrorRH] = useState('');
  const [errorCR, setErrorCR] = useState('');

  const { control, handleSubmit } = useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RHonSubmit = (data) => {
    setLoading(true);

    servicesRh.user
      .login({ credential: data.rhEmail, password: data.rhPassword })
      .then((resp) => {
        setUser(resp.data);
        localStorage.setItem(userKey, JSON.stringify(resp.data));
        dispatch({ type: 'SET_USER', user: resp.data });
        history.push('/rh/home');
      })
      .catch((err) => {
        // console.log(err);
        setErrorRH(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CRonSubmit = (data) => {
    setLoading(true);

    services.user
      .login({ credential: data.corretorCnpj, password: data.corretorPassword })
      .then((resp) => {
        // console.log('CRonSubmit', resp);
        localStorage.setItem(userKey, JSON.stringify(resp.data));
        dispatch({ type: 'SET_USER', user: resp.data });

        //context
        setUser(resp.data);

        history.push('/corretor/home');
      })
      .catch((err) => {
        setErrorCR(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setLoading(false);
  };

  useEffect(() => {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);

    if (userData?.corretora) {
      history.push('/corretor/home');
    }
    if (userData?.estipulante) {
      history.push('/rh/home');
    }
  }, [history]);

  return (
    <Wraper>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <header>
        <Container maxWidth="xl">
          <div className="top-area">
            <a className="nav-logo" href="/">
              <img src={LogoInnoa} width="150" alt="" loading="lazy" />
            </a>
          </div>
        </Container>
      </header>
      <main>
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <div className={classes.root}>
                  <AppBar
                    className={classes.header}
                    position="static"
                    color="default"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab
                        className={classes.tabs}
                        label="RH"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className={classes.tabs}
                        label="Corretor"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </AppBar>

                  <TabPanel value={value} index={0}>
                    <CardContent>
                      <div className="title">
                        <h1>Login RH</h1>
                      </div>
                      <div className="form">
                        <form
                          validate="true"
                          autoComplete="off"
                          onSubmit={handleSubmit(RHonSubmit)}
                        >
                          <div className="flex-center">
                            {errorRH && (
                              <div className="erro-msg"> {errorRH}</div>
                            )}
                          </div>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="rhEmail"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="email"
                                  label="E-mail"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="rhPassword"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="password"
                                  label="Senha"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <div className="flex-center mb-1">
                            <button type="submit">
                              Entrar{' '}
                              <span className="icon">
                                <FiLogIn />
                              </span>{' '}
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="form-link">
                        <div>
                          <Link to="/recuperar-senha">
                            {' '}
                            Esqueci a minha senha
                          </Link>
                        </div>
                      </div>
                    </CardContent>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CardContent>
                      <div className="title">
                        <h1>Login Corretor</h1>
                      </div>

                      <div className="form">
                        <form
                          validate="true"
                          autoComplete="off"
                          onSubmit={handleSubmit(CRonSubmit)}
                        >
                          <div className="flex-center">
                            {errorCR && (
                              <div className="erro-msg"> {errorCR}</div>
                            )}
                          </div>
                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="corretorCnpj"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={field.value.replace(
                                    regexCNPJ,
                                    '$1.$2.$3/$4-$5'
                                  )}
                                  label="CNPJ / E-MAIL"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <FormControl fullWidth className="form-controll">
                            <Controller
                              name="corretorPassword"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="password"
                                  label="Senha"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          <div className="flex-center mb-1">
                            <button type="submit">
                              Entrar{' '}
                              <span className="icon">
                                <FiLogIn />
                              </span>{' '}
                            </button>
                          </div>
                        </form>
                      </div>

                      <div className="form-link">
                        <div>
                          <Link to="/recuperar-senha">
                            Esqueci a minha senha
                          </Link>
                        </div>
                      </div>
                    </CardContent>
                  </TabPanel>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Wraper>
  );
}

export default Login;
