import { useState, useEffect } from 'react';
import Header from 'apps/broker/components/Header';
import Sidemenu from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import Filters from './components/Filters';
import services from 'apps/broker/services';
import TableResult from './components';
import AddBeneficiary from './components/AddBeneficiary';
import './style.scss';

const BeneficiariosCorretor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [beneficiariosFilter, setBeneficiariosFilter] = useState([]);
  const [allEstipulantes, setAllEstipulantes] = useState([]);
  const [addFilters, setAddFilters] = useState({
    nomeBeneficiario: '',
    status: 'Ativo'
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [allEstipulanteAndSubs, setAllEstipulanteAndSubs] = useState([]);
  const [estipulanteAndSubsSelected, setEstipulanteAndSubsSelected] = useState(
    []
  );
  const [estipulantePlanoSelected, setEstipulantePlanoSelected] = useState([]);
  const [estipulanteSelected, setEstipulanteSelected] = useState();
  const [selectedEstipulante, setSelectedEstipulante] = useState();

  const getBeneficiarios = async (estipulante_id) => {
    const company = allEstipulantes.filter(
      (item) => item.id === estipulante_id
    );

    setSelectedEstipulante(company.razao_social);
    setLoading(true);

    const response =
      await services.beneficiaries.getSubCompaniesAndBeneficiariesByCompanyId(
        estipulante_id
      );

    if (response.status === 200) {
      setLoading(false);
      const allBeneficiaries = response.data;
      setBeneficiarios(allBeneficiaries);

      setAddFilters((prevState) => ({
        ...prevState,
        nomeBeneficiario: ''
      }));

      const beneciariesFiltered = allBeneficiaries.filter(
        (item) => item.status === addFilters['status']
      );
      setBeneficiariosFilter(beneciariesFiltered);
    }
  };

  useEffect(() => {
    if (allEstipulantes.length > 0) {
      setSelectedEstipulante(allEstipulantes[0].razao_social);

      getBeneficiarios(allEstipulantes[0].id);
    }
  }, [allEstipulantes]);

  return (
    <div className="broker-beneficiaries">
      <Sidemenu />
      <div className="broker-beneficiaries-content">
        <Header />
        <Main>
          <div>
            <div className="broker-beneficiaries-consult">
              <div>
                <h1 className="broker-beneficiaries-consult__title">
                  Consulta Beneficiários
                </h1>
              </div>
              <div>
                <AddBeneficiary allEstipulantes={allEstipulantes} />
              </div>
            </div>
            <Filters
              setMasterCheckboxChecked={setMasterCheckboxChecked}
              setSelectedCheckboxes={setSelectedCheckboxes}
              allEstipulantes={allEstipulantes}
              setSelectedEstipulante={setSelectedEstipulante}
              selectedEstipulante={selectedEstipulante}
              setAddFilters={setAddFilters}
              addFilters={addFilters}
              estipulanteSelected={estipulanteSelected}
              setEstipulanteAndSubsSelected={setEstipulanteAndSubsSelected}
              setAllEstipulanteAndSubs={setAllEstipulanteAndSubs}
              setCurrentPage={setCurrentPage}
              getBeneficiarios={getBeneficiarios}
              setEstipulanteSelected={setEstipulanteSelected}
              setEstipulantePlanoSelected={setEstipulantePlanoSelected}
              estipulantePlanoSelected={estipulantePlanoSelected}
              estipulanteAndSubsSelected={estipulanteAndSubsSelected}
              allEstipulanteAndSubs={allEstipulanteAndSubs}
              beneficiariosFilter={beneficiariosFilter}
            />

            <TableResult
              {...{
                loading,
                estipulanteAndSubsSelected,
                estipulanteSelected,
                addFilters,
                setAddFilters,
                beneficiarios,
                setAllEstipulantes,
                estipulantePlanoSelected,
                setBeneficiariosFilter,
                beneficiariosFilter,
                getBeneficiarios,
                setLoading,
                setEstipulantePlanoSelected,
                setSelectedCheckboxes,
                selectedCheckboxes,
                setBeneficiarios,
                currentPage,
                setCurrentPage,
                setMasterCheckboxChecked,
                masterCheckboxChecked,
                setEstipulanteSelected,
                setEstipulanteAndSubsSelected,
                setAllEstipulanteAndSubs
              }}
            />
          </div>
        </Main>
      </div>
    </div>
  );
};

export default BeneficiariosCorretor;
