import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import services from 'apps/broker/services';

const ClientLogoForm = ({ details, estipulante, getData }) => {
  const [errorFormato, setErrorFormato] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [respLogo, setRespLogo] = useState(null);

  const { register, handleSubmit, setError, setValue, watch } = useForm();

  const handleImgChange = ({ target }) => {
    setErrorFormato(null);
    setRespLogo(null);
    setError('arquivo', null);

    const file = target.files && target.files[0];

    if (file && ['image/jpeg', 'image/png'].includes(file.type)) {
      setValue('arquivo', file);
      const urlImage = URL.createObjectURL(file);
      setImagePreviewUrl(urlImage);
    } else {
      setErrorFormato('Envie um logo no formato PNG ou JPG');
    }
  };

  const submitLogo = (data) => {
    const formData = new FormData();
    formData.append('file', data.arquivo);

    services.policyOwner
      .createLogoByUUID(formData, estipulante)
      .then((resp) => {
        setRespLogo(resp.data);
        getData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h3 className="titileEditoLogo">Logo do Cliente</h3>
      <div className="logoAtual">
        {!imagePreviewUrl && details.logo && (
          <img src={details.logo} alt="logo" />
        )}
      </div>
      <div>
        <form onSubmit={handleSubmit(submitLogo)}>
          {errorFormato && <span className="error-msg">{errorFormato}</span>}
          <div className="preview">
            <div className="logoPreview">
              {imagePreviewUrl && <img src={imagePreviewUrl} alt="logo" />}
              {respLogo && (
                <span className="message animeLeft">Logo adicionado.</span>
              )}
            </div>
          </div>
          <div className="div-input-files">
            <label className="label-file">
              {watch('arquivo')?.[0]?.name ? (
                <span>
                  {`${watch('arquivo')[0]?.name.slice(0, 45)}`}{' '}
                </span>
              ) : (
                <span>
                  {' '}
                  <BiPlus /> Adicionar ou trocar logo{' '}
                </span>
              )}
              <input
                {...register('arquivo')}
                onChange={(e) => handleImgChange(e)}
                className="input-file"
                type="file"
              />
            </label>
            <div className="buttons">
              {watch('arquivo')?.[0]?.name && (
                <button
                  type="button"
                  onClick={() => {
                    setValue('arquivo', null);
                    setImagePreviewUrl(null);
                    setRespLogo(null);
                  }}
                  className="btn-clear"
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          </div>
          <button className="send-btn" type="submit">
            Salvar
          </button>
        </form>
      </div>
    </>
  );
};

export default ClientLogoForm;
