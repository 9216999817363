import styled from 'styled-components';
import colors from 'styles/colors';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  gap: 8px;
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: ${props =>
    props.disabled ? '#f9fafb' :
      props.current ? colors.newGenoaDarkBlue : 'white'
  };
  color: ${props => props.current ? 'white' : 'black'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.current ? 'none' : 'auto'};
  transition: background-color 0.2s ease;

  &:hover:not(:disabled) {
    background-color: ${props => props.current ? colors.newGenoaDarkBlue : '#f3f4f6'};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;