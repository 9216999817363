import { useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from 'components/DS/Button';
import RegistrationData from '../PolicyOwnerDetails/components/TabsHeader/RegistrationData';
import './styles.scss';

const RegistrationInformation = ({ brokerInformation, onFetchPolicyOwners }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedView, setSelectedView] = useState('cadastro');
  const [openModal, setOpenModal] = useState(false);
  const [loadGestor, setLoadGestor] = useState(false);

  return (
    <div className="registration-information">
      <Button
        variant="transparent"
        onClick={() => setIsOpenDrawer(!isOpenDrawer)}
      >
        Dados Cadastrais
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(!isOpenDrawer)}
        onOpen={() => setIsOpenDrawer(!isOpenDrawer)}
      >
        <div className="registration-information__drawer">
          {selectedView === 'cadastro' && (
            <RegistrationData
              details={brokerInformation}
              emailsEmpresa={brokerInformation.emailsEmpresa}
              openModal={openModal}
              setOpenModal={setOpenModal}
              estipulante={brokerInformation.id_estipulante}
              setLoadGestor={setLoadGestor}
              getData={onFetchPolicyOwners}
            />
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default RegistrationInformation;
