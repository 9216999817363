import { BsFilterLeft } from 'react-icons/bs';
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp
} from 'react-icons/hi';

const OrderRequestDate = ({
  setOrderSelected,
  orderSelected,
  styleClassIcons
}) => {
  const filterOrderClickHandler = () => {
    setOrderSelected((prevState) => {
      if (prevState === 3) {
        return 1;
      }
      return prevState + 1;
    });
  };

  const renderOrderFilterSelected = () => {
    switch (orderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className={styleClassIcons}
            onClick={filterOrderClickHandler}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className={styleClassIcons}
            onClick={filterOrderClickHandler}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className={styleClassIcons}
            onClick={filterOrderClickHandler}
          />
        );
    }
  };

  return renderOrderFilterSelected();
};

export default OrderRequestDate;
