import PopperDeleteConfirm from '../popperDeleteConfirmation';
import './style.scss';

const TableHeader = ({
  anchorElDelete,
  handleClose,
  handleDisplayMessage,
  popupPositiveClickHandler
}) => {
  return (
    <div className="manager-request-table-header">
      <div>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Nome</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Produto</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Status</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Revisar</span>
      </div>
      <div className="manager-request-table-header__column-name--modal">
        <PopperDeleteConfirm
          anchorElDelete={anchorElDelete}
          handleClose={handleClose}
          handleDisplayMessage={handleDisplayMessage}
          popupPositiveClickHandler={popupPositiveClickHandler}
        />
      </div>
    </div>
  );
};

export default TableHeader;
