import React from 'react';
import { useForm } from 'react-hook-form';

const EditSubEstipulanteForm = ({
  subestipulante,
  onSubmit,
  onCancel,
  successSubEst,
  setEditSubEstipulante
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      cnpj: subestipulante.cnpj,
      razao_social: subestipulante.razao_social,
      cep: subestipulante.cep,
      logradouro: subestipulante.logradouro,
      numero: subestipulante.numero,
      complemento: subestipulante.complemento || '',
      localidade: subestipulante.localidade,
      id_estipulante: subestipulante.id_estipulante,
      sub: true
    }
  });

  return (
    <div className="addGestor">
      <form onSubmit={handleSubmit(onSubmit)}>
        {successSubEst && (
          <span className="message animeLeft">
            Informações salvas.
          </span>
        )}
        <div className="input-group">
          <label>CNPJ</label>
          <input
            maxLength={50}
            {...register('cnpj', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>Razão Social</label>
          <input
            maxLength={50}
            {...register('razao_social', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>CEP</label>
          <input
            maxLength={50}
            {...register('cep', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>Endereço</label>
          <input
            maxLength={50}
            {...register('logradouro', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>Número</label>
          <input
            type="text"
            {...register('numero', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>Complemento</label>
          <input
            {...register('complemento', { required: false })}
          ></input>
        </div>
        <div className="input-group">
          <label>Localidade</label>
          <input
            {...register('localidade', { required: false })}
          ></input>
        </div>

        <input
          type="hidden"
          {...register('id_estipulante')}
        />

        <input
          type="hidden"
          {...register('sub')}
        />

        <div className="btn-area">
          <button
            className="send-btn"
            type="submit"
          >
            Atualizar
          </button>
          <button
            className="send-btn"
            type="button"
            onClick={(e) => {
              setEditSubEstipulante(false);
              onCancel();
            }}
          >
            {successSubEst ? 'Fechar' : 'Cancelar'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSubEstipulanteForm;
