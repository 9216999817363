import { useState } from 'react';
import { ContentTabs } from '../../styles';
import { BlockInfo } from './styles';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { BiUserPin } from 'react-icons/bi';
import { FaBuilding, FaSave } from 'react-icons/fa';
import services from 'apps/broker/services';
import DialogRemoval from './DialogRemoval';
import AddSubestipulante from '../../Atualizacao-Estipulantes/AddSubestipulante-atualizacao';
import EditEstipulanteForm from './EditEstipulanteForm';
import EditSubEstipulanteForm from './EditSubEstipulanteForm';
import { useForm } from 'react-hook-form';
import ClientLogoForm from './ClientLogoForm';

const RegistrationData = ({
  details,
  emailsEmpresa,
  openModal,
  setOpenModal,
  estipulante,
  setLoadGestor,
  getData
}) => {
  const [addColaborador, setAddColaborador] = useState(false);

  const [editEstipulante, setEditEstipulante] = useState(false);
  const [editSubEstipulante, setEditSubEstipulante] = useState(false);
  const [editGestor, setEditGestor] = useState(false);
  const [delEmail, setDelEmail] = useState(false);

  const [removeGest, setRemoveGest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successEst, setSuccessEst] = useState(false);
  const [successSubEst, setSuccessSubEst] = useState(false);
  const [idGestorRemove, setIdGestorRemove] = useState(null);

  const saveGestorForm = useForm();
  const updateGestorInfo = useForm();

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  //form gestor
  const saveGestor = (data) => {
    const getForm = document.querySelector('#formAddGestor');
    setSuccess(false);
    setLoadGestor(true);

    services.policyOwner
      .createManagersByUUID(data, estipulante)
      .then(() => {
        setSuccess(true);
        getForm.reset();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadGestor(false);
        setTimeout(() => {
          saveGestorForm.reset({ nome: '', email: '' });
          setSuccess(false);
          setAddColaborador(false);
        }, 1000);
      });
  };

  const updateGestor = (data) => {
    services.policyOwner
      .updateManagerByUUID(data, estipulante)
      .then(() => {
        updateGestorInfo.reset({ telefone: '', email: '' });
        setEditGestor(false);
        getData();
      })
      .catch((err) => console.log(err));
  };

  const removeEmail = (e, email_empresa, id_estipulante) => {
    e.preventDefault();
    const data = {
      email_empresa,
      id_estipulante
    };
    services.policyOwner
      .deleteEmailById(data)
      .then(() => {
        setDelEmail(false);
        getData();
      })
      .catch((err) => console.log(err));
  };

  const removeGestor = (e, rhuser) => {
    services.policyOwner
      .deleteManagerByUUID(rhuser, estipulante)
      .then(() => {
        getData();
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateEstipulante = (data) => {
    if (data.sub) setSuccessSubEst(false);
    else setSuccessEst(false);

    if (data.dataEstipulante) {
      data.data_inicio_vinculo = data.dataEstipulante;
      delete data.dataEstipulante;
    }

    if (data.sub) delete data.sub;

    setLoadGestor(true);
    services.policyOwner
      .updateById(data)
      .then(() => {
        if (data.sub) setSuccessSubEst(true);
        else setSuccessEst(true);
        getData();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadGestor(false);
      });
  };

  const handleUpdateSubEstipulante = (data) => {
    if (data.sub) setSuccessSubEst(false);
    else setSuccessEst(false);

    setLoadGestor(true);
    services.policyOwner
      .updateById(data)
      .then(() => {
        if (data.sub) setSuccessSubEst(true);
        else setSuccessEst(true);
        getData();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadGestor(false);
      });
  };

  return (
    <>
      <ContentTabs>
        <section className="dadosCadastrais">
          <div className="dados-cadastrais-title">
            <span>Cadastro de Estipulante</span>
          </div>
          <div className="rows">
            <div className="cols">
              <BlockInfo>
                <div className="infos">
                  <div className="blok-info estipulante">
                    <div className="divIcon">
                      <FaBuilding />
                    </div>
                    <div>
                      <div className="header-info">
                        <span>{details.razao_social}</span>
                        <button
                          className="btn-edit"
                          onClick={(e) => setEditEstipulante(true)}
                        >
                          <FiEdit />
                        </button>
                      </div>
                      {editEstipulante ? (
                        <EditEstipulanteForm
                          details={details}
                          emailsEmpresa={emailsEmpresa}
                          onSubmit={handleUpdateEstipulante}
                          onCancel={() => setEditEstipulante(false)}
                          successEst={successEst}
                          removeEmail={removeEmail}
                          setDelEmail={setDelEmail}
                          delEmail={delEmail}
                          setSuccessEst={setSuccessEst}
                          setEditEstipulante={setEditEstipulante}
                        />
                      ) : (
                        <div className="dados">
                          <span>{details.cnpj}</span>
                          <span>
                            {details.logradouro +
                              ' ' +
                              details.numero +
                              ' ' +
                              details.complemento}
                          </span>
                          <span>{details.localidade}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="blok-info sub-estipulante">
                    <div className="divIcon">
                      <FaBuilding />
                    </div>
                    <div className="subestipulante_column">
                      {details?.subestipulantes?.map(
                        (subestipulante, index) => {
                          return (
                            <div key={index}>
                              <div className="header-info">
                                <span>
                                  {subestipulante.razao_social}
                                  <br />
                                  <span className="subsEs">
                                    (subestipulante)
                                  </span>
                                </span>
                                <button
                                  className="btn-edit"
                                  onClick={(e) => setEditSubEstipulante(index)}
                                >
                                  <FiEdit />
                                </button>
                              </div>
                              {editSubEstipulante === index ? (
                                <EditSubEstipulanteForm
                                  subestipulante={subestipulante}
                                  onSubmit={handleUpdateSubEstipulante}
                                  onCancel={() => setEditSubEstipulante(false)}
                                  successSubEst={successSubEst}
                                  setEditSubEstipulante={setEditSubEstipulante}
                                />
                              ) : (
                                <div className="dados">
                                  <span>{subestipulante.cnpj}</span>
                                  <span>
                                    {subestipulante.logradouro +
                                      ' ' +
                                      subestipulante.numero +
                                      ' ' +
                                      subestipulante.complemento}
                                  </span>
                                  <span>{subestipulante.localidade}</span>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn-add add-sub"
                  onClick={() => setOpenModal((prevState) => !prevState)}
                >
                  {' '}
                  + Adicionar Sub
                </button>

                {openModal && (
                  <AddSubestipulante
                    estipulante={details.id_estipulante}
                    subs={details.subestipulantes}
                    getDataDrawer={getData}
                    setOpenModal={setOpenModal}
                  />
                )}
              </BlockInfo>
              <div className="dados-cadastrais-title">
                <span>Contatos RH e outros</span>
              </div>
              <BlockInfo>
                <div className="contatos-container">
                  {details?.rh?.map((contato, index) => {
                    return (
                      <div className="contato-info" key={index}>
                        <div className="divIcon">
                          <BiUserPin />
                        </div>
                        <div className="infos-container">
                          <div className="gridcard">
                            <div>
                              <div className="title">
                                <div className="name">
                                  {contato.nome} {contato.sobrenome}
                                </div>
                              </div>
                              <div className="dados">
                                {editGestor === index ? (
                                  <div className="addGestor">
                                    <form
                                      onSubmit={updateGestorInfo.handleSubmit(
                                        updateGestor
                                      )}
                                    >
                                      <div className="input-group">
                                        <label>Telefone</label>
                                        <input
                                          defaultValue={contato.telefone}
                                          maxLength={50}
                                          {...updateGestorInfo.register(
                                            'telefone',
                                            { required: false }
                                          )}
                                        />
                                      </div>
                                      <div className="input-group">
                                        <label>E-mail</label>
                                        <input
                                          defaultValue={contato.email}
                                          maxLength={50}
                                          {...updateGestorInfo.register(
                                            'email',
                                            { required: false }
                                          )}
                                        />
                                      </div>
                                      {updateGestorInfo.setValue(
                                        'usuario_rh_id',
                                        contato.usuario_rh_id
                                      )}
                                      <div className="btn-remove">
                                        <button
                                          className="send-btnRemove"
                                          type="submit"
                                        >
                                          Alterar
                                        </button>
                                        <button
                                          className="send-btnRemove"
                                          type="button"
                                          onClick={(e) => setEditGestor(false)}
                                        >
                                          Cancelar
                                        </button>
                                      </div>
                                      <br />
                                    </form>
                                  </div>
                                ) : (
                                  <div>
                                    <p>{contato.telefone}</p>
                                    <p>{contato.email}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            {removeGest &&
                            idGestorRemove === contato.usuario_rh_id ? (
                              <DialogRemoval
                                contato={contato}
                                removeGestor={removeGestor}
                                setRemoveGest={setRemoveGest}
                              />
                            ) : (
                              <div className="btn-select">
                                <button
                                  className="send-btnRemove"
                                  type="button"
                                  title="Editar"
                                  onClick={(e) => setEditGestor(index)}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  className="send-btnRemove red"
                                  type="button"
                                  title="Excluir"
                                  onClick={(e) => {
                                    handleClickOpenModal();
                                    setIdGestorRemove(contato.usuario_rh_id);
                                    setRemoveGest(true);
                                  }}
                                >
                                  <FiXCircle />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="btn-add"
                    onClick={() => setAddColaborador(true)}
                  >
                    {' '}
                    + Adicionar Contato
                  </button>
                  {addColaborador && (
                    <div className="card">
                      <div className="addGestor">
                        <form
                          id="formAddGestor"
                          onSubmit={saveGestorForm.handleSubmit(saveGestor)}
                        >
                          {success && (
                            <span className="message animeLeft">
                              Acesse o email pra confirmar o cadastro.
                            </span>
                          )}
                          <div className="input-group">
                            <label>Nome</label>
                            {saveGestorForm.formState.errors.nome && (
                              <span className="error-msg">
                                {' '}
                                Campo Obrigatório
                              </span>
                            )}
                            <input
                              id="formAddGestor"
                              maxLength={50}
                              {...saveGestorForm.register('nome', {
                                required: true
                              })}
                            />
                          </div>

                          <div className="input-group">
                            <label>Email</label>
                            {saveGestorForm.formState.errors.email && (
                              <span className="error-msg">
                                {' '}
                                Campo Obrigatório
                              </span>
                            )}
                            <input
                              id="formAddGestor"
                              {...saveGestorForm.register('email', {
                                required: true
                              })}
                            />
                          </div>
                          <div className="btn-area">
                            <button className="send-btn" type="submit">
                              <span>Salvar</span>
                              <FaSave />
                            </button>
                            <button
                              className="send-btnRemove"
                              type="button"
                              onClick={() => setAddColaborador(false)}
                            >
                              Cancelar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </BlockInfo>
            </div>
            <div className="cols">
              <ClientLogoForm
                details={details}
                estipulante={estipulante}
                getData={getData}
              />
            </div>
          </div>
        </section>
      </ContentTabs>
    </>
  );
};

export default RegistrationData;
